// =======================================
//     List of variables for layout
// =======================================

$header-height: 70px;
$sidebar-width: 280px;
$sidebar-collapsed-width: 70px;

// header
$header-background: #fff;
$header-color: #525b69;
$header-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
$brand-color: #161c25;


// Menu
$sidebar-background: #1c232f;
$sidebar-color: #ced4dc;
$sidebar-icon-color: #778290;
$sidebar-active-color: $primary;
$sidebar-caption: $primary;
$sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

// horizontal menu
$topbar-height: 60px;
$topbar-background: #1c232f;
$topbar-color: #b5bdca;
$header-submenu-background: #fff;
$header-submenu-color: #1c232f;


// card block
$card-shadow:  0 2px 6px -1px rgba(0, 0, 0, 0.1);

$soft-bg-level: -80%;
